import axios from 'axios';
import { API_URL } from '../config/constants';
import removeAuthToken from '../utils/removeAuthToken';
import { userFormReset, userFormError, userFormSuccess } from '../actions/userForm';
import { closeUsersCreate } from './modals';
import { setSelectedAssociation } from './selectedAssociation';

export const setUsers = (users) => ({
    type: 'SET_USERS',
    users
});

export const setUserFormSelectedData = (vendors, municipalities, associations) => ({
    type: 'SET_USER_SELECT_DATA',
    vendors, 
    municipalities, 
    associations
});

export const setUsersLoading = () => ({
    type: 'SET_USERS_LOADING'
});

export const getUsers = () => {

    return (dispatch) => {

        dispatch(setUsersLoading());
        return axios.get(API_URL+'/api/users/')
            .then(function(res) {
                if (res.data.status == 'success') {
                    // dispatch(setUsers(res.data.users));
                    dispatch(setUserFormSelectedData(res.data.vendors, res.data.municipalities, res.data.associations));
                    dispatch(setSelectedAssociation(res.data.association));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get users!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const searchUsers = (search) => {

    return (dispatch) => {

        dispatch(setUsersLoading());
        return axios.post(API_URL+'/api/users/search', { search })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setUsers(res.data.users));
                }
                else {
                    console.log(res.data);
                    console.log("Could not get users!");
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const addUser = (id, { first_name, last_name, email, role, password, confirm_password, vendor_id, municipality_id, association_id } = {}) => {
    
    return (dispatch) => {

        dispatch(setUsersLoading());
        return axios.post(API_URL+'/api/users/', { first_name, last_name, email, role, password, confirm_password, vendor_id, municipality_id, association_id })
            .then(function(res) {
                if (res.data.status == 'success') {

                    // @todo: fix this later, we are not returning the full list of users
                    // dispatch(setUsers(res.data.users));
                    dispatch(userFormReset());
                    dispatch(closeUsersCreate());
                }
                else {
                    dispatch(userFormError(res.data.message));
                }
            })
            .catch(function(err) {
                
                // token is likely invalid; remove it and prompt the user to login again
                dispatch(userFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const updateUser = (id, { first_name, last_name, password, confirm_password }) => {
    
    return (dispatch) => {

        dispatch(setUsersLoading());
        return axios.put(API_URL+'/api/users/'+id, { first_name, last_name, password, confirm_password })
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(userFormSuccess('User Updated!'));
                }
                else {
                    dispatch(userFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not update your user!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                dispatch(userFormError("We're sorry, but our system has encountered an issue, to which we have been notified."));
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
};

export const removeUser = (mid, id) => {

    return (dispatch) => {

        dispatch(setUsersLoading());
        return axios.delete(API_URL+'/api/users/'+id+'/'+mid, {})
            .then(function(res) {
                if (res.data.status == 'success') {
                    dispatch(setUsers(res.data.users));
                }
                else {
                    // dispatch(userFormError(res.data.message));
                    console.log(res.data);
                    console.log("Could not remove your user!");
                }
            })
            .catch(function(err) {

                // token is likely invalid; remove it and prompt the user to login again
                if (err.response.status === 401) {
                    removeAuthToken(dispatch);
                }
                
            });
    };
}