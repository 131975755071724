import React from "react";
import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import GridItem from "./common/GridItem";
import GridContainer from "./common/GridContainer";
import Table from "./common/Table";
import Card from "./common/Card";
import CardHeader from "./common/CardHeader";
import CardBody from "./common/CardBody";
import verifyLogin from '../utils/verifyLogin';
import validateEmail from '../utils/validateEmail';
import { getUsers, addUser, searchUsers, updateUser, removeUser } from '../actions/users';
import { userFormUpdate, userFormError, userFormReset } from '../actions/userForm';
import { openUsersCreate, closeUsersCreate, openUsersUpdate, closeUsersUpdate } from '../actions/modals';
import { setSelectedAssociationID } from '../actions/selectedAssociation';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    }
  };


class UsersPage extends React.Component {

    constructor(props) {
        super(props);

        this.toggleCreateModal = this.toggleCreateModal.bind(this);
        this.toggleUpdateModal = this.toggleUpdateModal.bind(this);
        this.createUser = this.createUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.searchUsers = this.searchUsers.bind(this);

        this.state = {
            selectedUser: {}
        };
    }

    removeUser({ id }) {
        if (confirm("Are you sure you want to remove this committee user? This cannot be undone")) {
            this.props.dispatch(removeUser(id, this.props.match.params.id));
        };
    }

    componentDidMount() {
        this.props.dispatch(setSelectedAssociationID(this.props.match.params.id));
        verifyLogin(this.props.history);

        this.props.dispatch(getUsers());
    }

    componentDidUpdate() {
        verifyLogin(this.props.history);
    }

    toggleCreateModal(isOpen) {
        if (isOpen) {
            this.props.dispatch(openUsersCreate());
        }
        else {
            this.props.dispatch(closeUsersCreate());
        }
    }

    toggleUpdateModal(isOpen, selectedUser) {
        if (isOpen) {
            this.setState(() => ({
                selectedUser
            }));
            this.props.dispatch(userFormReset());
            this.props.dispatch(userFormUpdate({ prop: 'first_name', value: selectedUser.first_name }));
            this.props.dispatch(userFormUpdate({ prop: 'last_name', value: selectedUser.last_name }));
            this.props.dispatch(userFormUpdate({ prop: 'email', value: selectedUser.email }));
            this.props.dispatch(openUsersUpdate());
        }
        else {
            this.props.dispatch(closeUsersUpdate());
        }
    }

    searchUsers() {
        if (this.props.userForm.search.trim() != '') {
            this.props.dispatch(searchUsers(this.props.userForm.search.trim()));
        }
    }

    createUser() {

        // perform some error checking
        if (this.props.userForm.first_name.trim() == "" || this.props.userForm.last_name.trim() == "" || this.props.userForm.email.trim() == "" || this.props.userForm.role.trim() == "") {

            // required fields aren't present
            this.props.dispatch(userFormError("Please enter a first name, last name, email address and user role."));
        }
        else if (!validateEmail(this.props.userForm.email.trim())) {

            // required fields aren't present
            this.props.dispatch(userFormError("Please enter a valid email address."));
        }
        else if ((this.props.userForm.role == 'vendor' || this.props.userForm.role == 'vendor_admin') && this.props.userForm.vendor_id == 0) {
            // required fields aren't present
            this.props.dispatch(userFormError("Please select a vendor."));
        }
        else if ((this.props.userForm.role == 'municipality' || this.props.userForm.role == 'municipality_admin') && this.props.userForm.municipality_id == 0) {
            // required fields aren't present
            this.props.dispatch(userFormError("Please select a municipality."));
        }
        else if ((this.props.userForm.role == 'association' || this.props.userForm.role == 'association_admin') && this.props.userForm.association_id == 0) {
            // required fields aren't present
            this.props.dispatch(userFormError("Please select an association."));
        }
        else {

            // add the committee user
            this.props.dispatch(addUser(this.props.match.params.id, this.props.userForm));
        }
    }

    updateUser() {

        // perform some error checking
        if (this.props.userForm.first_name.trim() == "" || this.props.userForm.last_name.trim() == "") {

            // required fields aren't present
            this.props.dispatch(userFormError("Please enter a valid user first name and last name."));
        }
        else if ((this.props.userForm.password.trim() !== '' || this.props.userForm.confirm_password.trim() !== '') && this.props.userForm.password !== this.props.userForm.confirm_password) {

            // passwords do not match
            this.props.dispatch(userFormError("Passwords do not match."));
        }
        else {

            // add the committee user
            this.props.dispatch(updateUser(this.state.selectedUser.id, this.props.userForm));
        }
    }

    renderCreateModal() {
        const { classes } = this.props;
        const vendors = [{id: '0', name: 'Select a Vendor ID'}].concat(this.props.vendors);
        const municipalities = [{id: '0', name: 'Select a Municipality ID'}].concat(this.props.municipalities);
        const associations = [{id: '0', name: 'Select a Association ID'}].concat(this.props.associations);

        return (
            <Modal
                aria-labelledby="association-user-add"
                aria-describedby="association-user-add"
                open={this.props.createModalOpen}
                onClose={() => this.toggleCreateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Add a Committee User
                    </Typography>
                    <Typography className="modal-description">
                        Please add the email of the user that you want to add, and the role that you want to assign to them.
                    </Typography>

                    { this.props.userForm.error && (
                        <Typography className="modal-error">
                            { this.props.userForm.error }
                        </Typography>
                    )}

                    <TextField
                        id="first-name"
                        label="First Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.first_name}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'first_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="last-name"
                        label="Last Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.last_name}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'last_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="standard-name"
                        label="Email Address"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.email}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'email', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="password"
                        label="Password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.password}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="confirm_password"
                        label="Confirm Password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.confirm_password}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'confirm_password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-user-role">Role</InputLabel>
                        <Select
                            value={this.props.userForm.role}
                            onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'role', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'role',
                                id: 'new-user-role',
                            }}
                        >
                            <MenuItem value="">
                                Select a Role
                            </MenuItem>
                            <MenuItem value={'admin'}>System Administrator</MenuItem>
                            <MenuItem value={'vendor_admin'}>Vendor Admin</MenuItem>
                            <MenuItem value={'vendor'}>Vendor</MenuItem>
                            <MenuItem value={'municipality_admin'}>Municipality Admin</MenuItem>
                            <MenuItem value={'municipality'}>Municipality</MenuItem>
                            <MenuItem value={'association_admin'}>Association Admin</MenuItem>
                            <MenuItem value={'association'}>Association</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id="standard-name"
                        label="Vendor ID"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.vendor_id}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'vendor_id', value: e.target.value })); }}
                        margin="normal"
                    />

                    {/* <FormControl className="form-control">
                        <InputLabel htmlFor="new-vendor-id">Vendor ID (If Applicable)</InputLabel>
                        <Select
                            value={this.props.userForm.vendor_id}
                            onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'vendor_id', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'vendor-id',
                                id: 'new-vendor-id',
                            }}
                        >
                            {vendors.map((vendor) => (
                                <MenuItem key={vendor.id} value={vendor.id}>
                                    {vendor.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-municipality-id">Municipality ID (If Applicable)</InputLabel>
                        <Select
                            value={this.props.userForm.municipality_id}
                            onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'municipality_id', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'municipality-id',
                                id: 'new-municipality-id',
                            }}
                        >
                            {municipalities.map((municipality) => (
                                <MenuItem key={municipality.id} value={municipality.id}>
                                    {municipality.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className="form-control">
                        <InputLabel htmlFor="new-association-id">Association ID (If Applicable)</InputLabel>
                        <Select
                            value={this.props.userForm.association_id}
                            onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'association_id', value: e.target.value })); }}
                            fullWidth={true}
                            className="modal-select"
                            inputProps={{
                                name: 'association-id',
                                id: 'new-association-id',
                            }}
                        >
                            {associations.map((association) => (
                                <MenuItem key={association.id} value={association.id}>
                                    {association.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.createUser}>
                        Add User
                    </Button>
                </div>
            </Modal>
        )
    }

    renderUpdateModal() {
        return (
            <Modal
                aria-labelledby="association-user-add"
                aria-describedby="association-user-add"
                open={this.props.updateModalOpen}
                onClose={() => this.toggleUpdateModal(false)}
                className="modal_admin"
            >
                <div className="modal-user">
                    <Typography variant="h4" className="modal-title">
                        Update User
                    </Typography>
                    <Typography className="modal-description">
                        Please update the user's name, email and password as necessary.
                    </Typography>

                    { this.props.userForm.error && (
                        <Typography className="modal-error">
                            { this.props.userForm.error }
                        </Typography>
                    )}

                    { this.props.userForm.message && (
                        <Typography className="modal-success">
                            { this.props.userForm.message }
                        </Typography>
                    )}


                    <TextField
                        id="first-name"
                        label="First Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.first_name}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'first_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="last-name"
                        label="Last Name"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.last_name}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'last_name', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="password"
                        label="Password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.password}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <TextField
                        id="confirm_password"
                        label="Confirm Password"
                        className="modal-input"
                        fullWidth={true}
                        value={this.props.userForm.confirm_password}
                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'confirm_password', value: e.target.value })); }}
                        margin="normal"
                    />

                    <Button variant="contained" fullWidth={true} color="primary" className="modal-button-bottom" onClick={this.updateUser}>
                        Update User
                    </Button>
                </div>
            </Modal>
        )
    }

    printRole(theRole) {
        switch(theRole) {

            case "admin":
                return "Administrator";

            case "vendor":
                return "Vendor";

            case "vendor_admin":
                return "Vendor Admin";

            case "municipality_admin":
                return "Municipality Admin";

            case "municipality":
                return "Municipality";

            case "association_admin":
                return "Association Admin";

            case "association":
                return "Association";

            default: 
                return theRole;
        }
    }

    printStatus(theStatus) {
        if (theStatus == "confirmed") {
            return "Confirmed";
        }
        else if (theStatus == "pending") {
            return "Pending";
        }
        else {
            return theStatus;
        }
    }

    renderUsersList() {
        const { classes } = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" className="card-header">
                            <p className={classes.cardCategoryWhite}>
                            Users
                            </p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={9}>
                                    <TextField
                                        className="modal-input"
                                        fullWidth={true}
                                        type="text"
                                        value={this.props.userForm.search}
                                        onChange={(e) => { this.props.dispatch(userFormUpdate({ prop: 'search', value: e.target.value })); }}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <Button 
                                        variant="contained" 
                                        size="large"
                                        fullWidth={true} 
                                        color="primary" 
                                        className="search-button" 
                                        onClick={this.searchUsers}
                                    >
                                        Search
                                    </Button>
                                </GridItem>
                            </GridContainer>
                            <Table
                                tableHead={["Name", "Email", "Role", "Phone", "Actions"]}
                                tableData={this.props.users.map((user) => [
                                    user.first_name+' '+user.last_name, 
                                    user.email, 
                                    this.printRole(user.roles[0].role),
                                    user.phone,
                                    (
                                        <div>
                                            {(user.role != "owner") && <Icon className="fa fa-pen manager-table-icon manager-table-edit" onClick={() => this.toggleUpdateModal(true, user)} />}
                                            {(user.role != "owner") && <Icon className="fa fa-trash manager-table-remove" onClick={() => this.removeUser(user)} />}
                                        </div>
                                    )
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
    
    render() {
        return (
            <div>
                {this.renderUsersList()}

                <Fab className="btn-fab btn-add" onClick={() => this.toggleCreateModal(true)}>
                    <AddIcon />
                </Fab>

                {this.renderCreateModal()}
                {this.renderUpdateModal()}
            </div>
        );
    }
};

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.users.loading,
        users: state.users.users,
        vendors: state.users.vendors,
        associations: state.users.associations,
        municipalities: state.users.municipalities,
        userForm: state.userForm,
        createModalOpen: state.modals.usersCreate,
        updateModalOpen: state.modals.usersUpdate,
        association: state.selectedAssociation.association
    };
};

export default connect(mapStateToProps)(withStyles(styles)(UsersPage));
